<template>
  <div style="padding:40px;">
    <v-row
      align="center"
    >
      <v-col 
        cols="12" 
        style="padding: 0 0 20px 0;margin:0;"
      >
        <v-btn-toggle
          v-model="environment"
          borderless
          color="primary"
        >
          <v-btn
            v-for="item of environments"
            :key="item.id"
            :value="item.id"
          >
            <span class="hidden-sm-and-down">{{item.name}}</span>
          </v-btn>
        </v-btn-toggle>
        <!--v-autocomplete
          v-model="environment"
          :items="environments"
          item-text="name"
          item-value="id"
          dense
          label="Entornos"
          solo
          hide-details
        /-->
      </v-col>
      <v-col 
        cols="12"
        v-if="logs"
        style="background-color: white; margin:0;padding:0 !important;"
      >
        <v-data-table
          :headers="headers"
          :items="logs"
          :single-expand="true"
          :expanded.sync="expanded"
          item-key="ID"
          show-expand
          hide-default-footer
          :items-per-page="-1"
          class="elevation-0"
        >
          <template v-slot:top>
            <v-toolbar flat>
              <v-toolbar-title>Logs</v-toolbar-title>
              <v-spacer/>
        <v-autocomplete
          v-model="method"
          :items="methods"
          item-text="name"
          item-value="id"
          dense
          label="M�todos"
          solo
          hide-details
        />
              <v-spacer/>
        <v-text-field
          outlined
          append-icon="mdi-magnify"
          :prepend-inner-icon="search !== '' ? 'mdi-close' : ''"
          hide-details
          dense
          style="margin-right:10px;"
          v-model="search"
          autofocus
          @click:append="handleGetData"
          @click:prepend-inner="handleClear"
          v-on:keyup.enter="handleGetData"
        />
              <v-spacer/>
              <v-btn @click="handleGetData">
                refrescar
              </v-btn>
            </v-toolbar>
          </template>
          <template v-slot:[`item.OriginalURL`]="{ item }">
            <url-info
              :url="item.OriginalURL"
              :environment="environment"
            />
          </template>
          <template v-slot:expanded-item="{ headers, item }">
            <td :colspan="headers.length" style="background-color: #f9ddb1;">
              <p>URL: {{item.OriginalURL}}</p>
              <url-info
                :url="item.OriginalURL"
                :environment="environment"
              />
              <br/>
              <br/>
              <p>CD: {{ item.CD }} </p>
              <p>IP: {{ item.IP }} </p>
              <p>Path: {{ item.Path }} </p>
              <v-layout wrap>
                <v-flex xs12 md6>
                  <p style="marging:0;">Body: </p>
                  <pre>
                    <code>
                      {{ prepareCode(item.Body) }}
                    </code>
                  </pre>
                </v-flex>
                <v-flex xs12 md6>
                  <p style="marging:0;">Query: </p>
                  <pre>
                    <code>
                      {{ prepareCode(item.Query) }}
                    </code>
                  </pre>
                </v-flex>
              </v-layout>
            </td>
          </template>
        </v-data-table>
      <pagination
        :startIndex="startIndex"
        :itemsPerPage="itemsPerPage"
        :onGetData="handleGetData"
        :locale="locale"
        :count="count"
        :onChangeItemsPerPage="handleChangeItemsPerPage"
      />
      </v-col>
    </v-row>
  </div>
</template>
<script>
import api from '@/services/api'
import Pagination from './Pagination'
import UrlInfo from './UrlInfo'
export default {
  components: {
    Pagination,
    UrlInfo,
  },
  data: () => ({
    environment: null,
    logs: [],
    expanded: [],
    method: null,
    search: null,
    startIndex: 0,
    itemsPerPage: 10,
    count: 0,
  }),
  computed: {
    locale () {
      return 'es'
    },
    environments () { 
      return [ 
        { 
          id: 'dviewcontent', 
          name: 'dviewcontent'
        },
        { 
          id: 'dtouch', 
          name: 'dtouch'
        },
        { 
          id: 'booking', 
          name: 'booking'
        },
        { 
          id: 'players', 
          name: 'players'
        },
        { 
          id: 'cm-api', 
          name: 'cm-api'
        },
        { 
          id: 'dfiles', 
          name: 'dfiles'
        }
      ]
    },
    methods () {
      return [
        {
          id: null,
          name: 'ALL',
        },
        {
          id: 'GET',
          name: 'GET',
        },
        {
          id: 'PATCH',
          name: 'PATCH',
        },
        {
          id: 'POST',
          name: 'POST',
        },
        {
          id: 'PUT',
          name: 'PUT',
        },
        {
          id: 'DELETE',
          name: 'DELETE',
        },
      ]
    },
    headers () {
      return [
        {
          text: 'Method',
          align: 'start',
          sortable: false,
          value: 'Method',
        },
        { text: 'UserName', value: 'UserName' },
        { text: 'OriginalURL', value: 'OriginalURL' },
        { text: 'CD', value: 'CD' },
        { text: '', value: 'data-table-expand' },
      ]
    },
  },
  watch: {
    environment () {
      this.handleGetData(0)
    },
    method () {
      this.handleGetData(0)
    },
  },
  mounted () {
    this.environment = this.environments[0].id
  },
  methods: {
    handleClear () {
      this.search = null
      this.handleGetData(0)
    },
    convertDate (v) {
      const options = { weekday: 'long', day: 'numeric', month: 'numeric', year: 'numeric' }
      const date = new Date(v).toLocaleDateString(this.locale, options)
      const time = new Date(v).toLocaleTimeString(this.locale)
      const today = new Date().toLocaleDateString(this.locale, options)
      const isToday = date === today ? true : false 
      return `${isToday ? 'Hoy ' : '' }${date} ${time}`
    },
    handleGetData (v) {
      if (v >= 0) this.startIndex = v
      this.logs = []
      if (!this.environment) return
      const filter = { 
        method: this.method,
        search: this.search && this.search.length > 0 ? this.search : null,
        startIndex: this.startIndex, 
        itemsPerPage: this.itemsPerPage, 
      }
      api.put(this.environment, 'v1/system/logs/', filter)
        .then(response => {
          this.count = response.data.count
          this.logs = response.data.data.map(item => {
            item.CD = this.convertDate(item.CD)
            return item
          })
        })
    },
    handleChangeItemsPerPage (v) {
      this.itemsPerPage = v
      this.handleGetData(0)
    },
    prepareCode (v) {
      try {
        return v ? JSON.parse(v) : {}
      } catch (error) {
        console.log(error)
        return {}
      }
    },
  },
}
</script>

